
(function ($, root, undefined) {
    
    $(function () {
        
        'use strict';


        // DOM ready, take it away
        
        (function() {
            vhCheck();
            $('html, body').addClass(getMobileOperatingSystem());
            html_structure_events ();

        }());


        function html_structure_events () {


            calcule_sizes();
            $( window ).resize(function() {
              calcule_sizes();
            });

            var lastScrollTop = 0;
            $(window).scroll(function(event){
                var st = $(this).scrollTop();

                var mueve;

                if (st > lastScrollTop){
                    mueve = 25;
                } else {
                    mueve = -25;
                }

                var piezas = $(".pieza");
                if(piezas.length > 0){
                    for (var i = 0; i < piezas.length; i++) {

                            var cuanto = Math.floor(Math.random() * mueve);
                            var delay = 1.1 * Math.random().toFixed(2);
                            var duration = 1.1 * Math.random().toFixed(2);

                            if(!$(piezas[i]).hasClass("pieza_trio_naranja")){
                                $(piezas[i]).css("margin-top",cuanto);
                            }else{
                                $(piezas[i]).css("top",cuanto);
                            }

                            $(piezas[i]).css("-webkit-animation-delay",delay+"s");
                            $(piezas[i]).css("-moz-animation-delay",delay+"s");
                            $(piezas[i]).css("-ms-animation-delay",delay+"s");
                            $(piezas[i]).css("-o-animation-delay",delay+"s");
                            $(piezas[i]).css("animation-delay",delay+"s");
                            $(piezas[i]).css("-webkit-animation-duration",duration+"s");
                            $(piezas[i]).css("-moz-animation-duration",duration+"s");
                            $(piezas[i]).css("-ms-animation-duration",duration+"s");
                            $(piezas[i]).css("-o-animation-duration",duration+"s");
                            $(piezas[i]).css("animation-duration",duration+"s");


                    }
                }



                lastScrollTop = st;
            });

            
        }



        function request_animation_frame() {

            var stop = false;
            var frameCount = 0;
            var $results = $("#results");
            var fps, fpsInterval, startTime, now, then, elapsed;

            startAnimating(24);

            function startAnimating(fps) {
                fpsInterval = 1000 / fps;
                then = Date.now();
                startTime = then;
                console.log(startTime);
                animate();
            }


            function animate() {

                if (stop) {
                    return;
                }

                requestAnimationFrame(animate);

                now = Date.now();
                elapsed = now - then;


                if (elapsed > fpsInterval) {
                    then = now - (elapsed % fpsInterval);
                    

                    /* Ejecutar en interval */ 


                }
            }
        }
        

        function calcule_sizes() {

        }



        function global_events() {

        }

    });


})(jQuery, this);
