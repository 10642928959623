// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        } 
    }
}());

// Place any jQuery/helper plugins in here.

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  } 
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}


function getOffset(el) {
  el = el.getBoundingClientRect();
  //console.log (el.top + window.scrollY);
  return {
    left: (el.left + window.scrollX),
    top: (el.top + window.scrollY)
  };
}

function getVendorPrefix(prop, value) {
    var webkit = '-webkit-' + prop;
    var moz = '-moz-' + prop;
    var ms = '-ms-' + prop;
    var o = '-o-' + prop;
    var css = prop;

    var prefixed = {};
    prefixed[webkit] = value;
    prefixed[moz] = value;
    prefixed[ms] = value;
    prefixed[o] = value;
    prefixed[css] = value;

    return prefixed;
}

function getVendorPrefixString(prop, value) {
    var webkit = '-webkit-' + prop;
    var moz = '-moz-' + prop;
    var ms = '-ms-' + prop;
    var o = '-o-' + prop;
    var css = prop;

    var prefixed = webkit + ':' + value + ';' + moz + ':' + value + ';' + ms + ':' + value + ';' + o + ':' + value + ';' + css + ':' + value +';';

    return prefixed;
}

/* Check MobileOS */
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var device_class = "";
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        device_class += "Windowsphone";
    }

    if (/android/i.test(userAgent)) {
         device_class += "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        device_class += "iOS";
    }

    if (device_class !== "") {

        if (jQuery(window).width() >= 768) {
            device_class += " tablet";
        } else {
            device_class += " mobile";
        }

    }

    return device_class;
}

/* Check Element is in ViewPort */
jQuery.fn.isInViewport = function() {
  var elementTop = jQuery(this).offset().top;
  var elementBottom = elementTop + jQuery(this).outerHeight();

  var viewportTop = jQuery(window).scrollTop();
  var viewportBottom = viewportTop + jQuery(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};